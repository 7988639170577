import React from 'react';
import { Flex } from 'rebass';

const ShoppingBag = (props) => {
  return (
    <Flex {...props} justifyContent="center">
      <svg
        width={
          props.width && !Array.isArray(props.width) ? props.width : '100%'
        }
        height={
          props.height && !Array.isArray(props.width) ? props.height : '100%'
        }
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="shopping-bag"
        className="svg-inline--fa fa-shopping-bag fa-w-14"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <g transform="scale(1 .83278)">
          <path fill="none" d="M0 0H500V600H0z"></path>
          <clipPath id="_clip1">
            <path d="M0 0H500V600H0z"></path>
          </clipPath>
          <g clip-path="url(#_clip1)">
            <path
              fill="#FFC033"
              fillRule="nonzero"
              d="M256 0c-31.219 0-61.152 5.618-88.839 15.895C69.661 52.086 0 146.062 0 256c0 141.157 114.843 256 256 256 141.157 0 256-114.843 256-256C512 114.843 397.157 0 256 0z"
              transform="matrix(.97464 0 0 1.17101 .186 1.662)"
            ></path>
            <path
              fill="#F9A926"
              fillRule="nonzero"
              d="M512 256C512 114.843 397.157 0 256 0v512c141.157 0 256-114.843 256-256z"
              transform="matrix(.97464 0 0 1.17101 .186 1.662)"
            ></path>
            <path
              fill="#6B7A99"
              fillRule="nonzero"
              d="M256 417.391c-21.478 0-38.957-17.478-38.957-38.957s17.478-38.957 38.957-38.957 38.957 17.478 38.957 38.957-17.479 38.957-38.957 38.957z"
              transform="matrix(.97464 0 0 1.17101 .186 1.662)"
            ></path>
            <path
              fill="#5A6680"
              fillRule="nonzero"
              d="M294.957 378.435c0-21.478-17.478-38.957-38.957-38.957v77.913c9.564 0 18.336-3.466 25.124-9.208 8.455-7.152 13.833-17.834 13.833-29.748z"
              transform="matrix(.97464 0 0 1.17101 .186 1.662)"
            ></path>
            <path
              fill="#6B7A99"
              fillRule="nonzero"
              d="M256 239.304c-75.185 0-136.348 61.163-136.348 136.348 0 25.278 7.038 48.888 19.074 69.206C75.616 405.521 33.391 335.684 33.391 256c0-92.942 57.258-172.766 138.351-206.05C197.744 39.278 226.198 33.391 256 33.391c56.772 0 102.957 46.185 102.957 102.957 0 56.772-46.185 102.956-102.957 102.956z"
              transform="matrix(.97464 0 0 1.17101 .186 1.662)"
            ></path>
            <path
              fill="#5A6680"
              fillRule="nonzero"
              d="M358.957 136.348c0-44.298-28.119-82.15-67.445-96.645-11.075-4.082-23.038-6.312-35.512-6.312v205.913c56.772 0 102.957-46.184 102.957-102.956z"
              transform="matrix(.97464 0 0 1.17101 .186 1.662)"
            ></path>
            <path
              fill="#FFC033"
              fillRule="nonzero"
              d="M256 94.609c-21.478 0-38.957 17.478-38.957 38.957s17.478 38.957 38.957 38.957 38.957-17.478 38.957-38.957S277.478 94.609 256 94.609z"
              transform="matrix(.97464 0 0 1.17101 .186 1.662)"
            ></path>
            <path
              fill="#F9A926"
              fillRule="nonzero"
              d="M294.957 133.565c0-21.478-17.478-38.957-38.957-38.957v77.913c21.478.001 38.957-17.477 38.957-38.956z"
              transform="matrix(.97464 0 0 1.17101 .186 1.662)"
            ></path>
          </g>
        </g>
      </svg>
    </Flex>
  );
};

export default ShoppingBag;
